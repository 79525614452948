import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, find2, listByCompany } from '../../services/offer'

function* fetchOffersRefresh() {
  const fe = JSON.parse(localStorage.getItem('fetchOffers'))
  if (fe == null) {
    fe.size = 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_OFFER,
    result,
  })
}

function* fetchOffers({ size, index, searchTerm, orderBy, companyID }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchOffers'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchOffers', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy, companyID)
  yield put({
    type: actions.LIST_OFFER,
    result,
  })
}

function* fetchCompanyOffers({ size, index, searchTerm, orderBy, companyID }) {
  console.log('fetchCompanyOffers', companyID)
  console.log('localStorage company id', localStorage.getItem('fetchOffers'))
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchOffers'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = '%20'
      orderBy = fe.orderBy || '%20'
      companyID = fe.companyID || '%20'
    } else {
      size = 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
      companyID = '%20'
    }
  }
  localStorage.setItem(
    'fetchOffers',
    JSON.stringify({ size, index, searchTerm, orderBy, companyID }),
  )
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(listByCompany, size, index, searchTerm, orderBy, companyID)
  yield put({
    type: actions.LIST_COMPANY_OFFER,
    result,
  })
}

function* fetchOfferEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_OFFER,
    result,
  })
}

function* fetchOfferEdit2({ id }) {
  console.log('fetchOfferEdit2', id)
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find2, id)
  yield put({
    type: actions.EDIT_OFFER2_REC,
    result,
  })
}

function* fetchOfferSave(values, silent) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null && silent === false) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.EDIT_OFFER,
    result,
  })
}

function* fetchOfferDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_OFFER_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_OFFERS,
  })
}

function* FETCH_OFFER_OPEN() {
  yield put({
    type: actions.FETCH_OFFER_OPEN_RECIEVED,
  })
}
function* FETCH_OFFER_CLOSE() {
  yield put({
    type: actions.FETCH_OFFER_CLOSE_RECIEVED,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_OFFER_OPEN, FETCH_OFFER_OPEN),
    takeEvery(actions.FETCH_OFFER_CLOSE, FETCH_OFFER_CLOSE),
    takeEvery(actions.FETCH_COMPANY_OFFERS, fetchCompanyOffers),

    takeEvery(actions.FETCH_OFFERS, fetchOffers),
    takeEvery(actions.EDIT_OFFER2, fetchOfferEdit2),
    takeEvery(actions.FETCH_OFFER, fetchOfferEdit),
    takeEvery(actions.SAVE_OFFER, fetchOfferSave),
    takeEvery(actions.DELETE_OFFER, fetchOfferDelete),
    takeEvery(actions.RE_FETCH_OFFERS, fetchOffersRefresh),
  ])
}
