import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Input, Checkbox, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import actions from 'redux/userss/actions'

export default () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { userss } = useSelector(state => ({
    userss: state.userss,
  }))

  useEffect(() => {
    const { profile } = userss
    if (profile !== undefined && profile !== null) {
      try {
        form.setFieldsValue({
          userName: profile.userName,
          gender: profile.gender !== null ? profile.gender.toString() : '',
          name: profile.name,
          surname: profile.surname,
          phone: profile.phone,
          mobile: profile.mobile,
          email: profile.email,
          password: profile.password,
          roleName: profile.roleName,
          prID: profile.prID,
          userImage: profile.userImage,
          isActive: profile.isActive,
          language: profile.language,
          passwordLocked: profile.passwordLocked,
          pageSize: profile.pageSize !== null ? profile.pageSize.toString() : '',
          professionName: profile.professionName,
          adUserName: profile.adUserName,
          windowsName: profile.windowsName,
        })
      } catch (e) {
        // console.log(e)
      }
      setVisible(true)
    }
    // eslint-disable-next-line
  }, [userss.profile])

  const onSubmit = () => {
    form
      .validateFields()
      .then(values => {
        const { profile } = userss
        values.userID = profile.userID
        setLoading(true)
        setTimeout(() => {
          dispatch({
            type: actions.SAVE_USERS,
            values,
          })
        }, 100)
      })
      .catch(hmm => {
        console.log(hmm)
      })
  }

  const onCancel = () => {
    setVisible(false)
    dispatch({ type: actions.RESET_USERS })
  }

  return (
    <div>
      <Modal
        title={t('edit.profile')}
        visible={visible}
        footer={null}
        getContainer={false}
        onCancel={onCancel}
        width="575px"
      >
        <Form
          form={form}
          {...{
            labelCol: { xs: { span: 24 }, sm: { span: 8 } },
            wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
          }}
          labelAlign="left"
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name)
          }}
        >
          <Form.Item name="name" label={t('name')}>
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item name="surname" label={t('surname')}>
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item style={{ display: 'none' }} name="userName" label={t('userName')}>
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item style={{ display: 'none' }} name="passwordLocked">
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item name="password" label={t('password')}>
            <Input autoComplete="off" type="password" />
          </Form.Item>

          <Form.Item style={{ display: 'none' }} name="roleName" label={t('role')}>
            <Select
              showSearch
              onSelect={value => {
                form.setFieldsValue({ roleName: value })
              }}
            >
              <Select.Option value="">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">-</span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="admin">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">{t('admin')}</span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="operator">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">{t('operator')}</span>
                  </span>
                </div>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="gender" label={t('gender')}>
            <Select
              showSearch
              onSelect={value => {
                form.setFieldsValue({ gender: value })
              }}
            >
              <Select.Option value="0">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">-</span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="1">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">{t('herr')}</span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="2">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">{t('frau')}</span>
                  </span>
                </div>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="email" label={t('email')}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="phone" label={t('phone')}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="mobile" label={t('mobile')}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="prID" label={t('prID')}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="professionName" label={t('professionName')}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="adUserName" label={t('adUserName')}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="windowsName" label={t('windowsName')}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="pageSize" label={t('pageSize')}>
            <Select
              onChange={value => {
                form.setFieldsValue({ pageSize: value })
              }}
            >
              <Select.Option value="15">15 / {t('items')}</Select.Option>
              <Select.Option value="30">30 / {t('items')}</Select.Option>
              <Select.Option value="60">60 / {t('items')}</Select.Option>
              <Select.Option value="100">100 / {t('items')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="language" label={t('language')}>
            <Select
              onSelect={value => {
                form.setFieldsValue({ language: value })
              }}
            >
              <Select.Option value="de">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">Deutsch</span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="en">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">English</span>
                  </span>
                </div>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            style={{ display: 'none' }}
            name="isActive"
            label={t('status')}
            valuePropName="checked"
          >
            <Checkbox>{t('isActive')}</Checkbox>
          </Form.Item>
          <div className="ant-modal-footer actions">
            <div className="row">
              <div className="col-md-5 col-sm-5 text-left" />
              <div className="col-md-7 col-sm-7">
                <Button key="back" onClick={onCancel}>
                  {t('cancel')}
                </Button>
                <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
                  {t('submit')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
