export const API_URL = process.env.REACT_APP_BASE_URL_SHARED_API
export const API_URL_REPORT = process.env.REACT_APP_BASE_URL_REPORT_API
export const SIGNALR_URL = process.env.REACT_APP_BASE_URL_SIGNALR
export const APPLICATION_NAME = 'CRM'
export const APPLICAITON_COPYRIGHT = '© 2023 Softlare GmbH. All rights reserved.'
export const VERSION = '2.2.3'

export const SAVE_200_MSG = 'Erfolgreich'
export const SAVE_200_DESC = 'Ihre Änderungen wurden gespeichert.'

export const DEL_200_MSG = 'Erfolgreich'
export const DEL_200_DESC = 'Ihre Artikel wurde gelöscht.'

export const NETWORK_ERROR = 'Netzwerkfehler'
export const NETWORK_ERROR_MESSAGE =
  'Netzwerk ist ausgefallen!. API-Service sind nicht erreichbar. Wenden Sie sich an Ihren Administrator.'

export const LOGGED_IN = 'Eingeloggt'
export const LOGGED_IN_MESSAGE = 'Sie haben sich erfolgreich bei Softlare CRM angemeldet'

export const LOGIN_FAILED = 'Anmeldung fehlgeschlagen'
export const LOGIN_FAILED_MESSAGE = 'Ihr Benutzername oder Passwort ist falsch.'
