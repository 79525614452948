import { getRequest, postRequest, logRequest, getCompanyID } from './api'

export async function list(size, index, searchTerm, orderBy, companyID) {
  logRequest('Show Offers')
  return getRequest(
    `offer/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}/${companyID || '%20'}`,
  )
}

export async function listByCompany(size, index, searchTerm, orderBy, companyID) {
  logRequest('Show Offers By Company')
  if (getCompanyID() !== '0') {
    companyID = getCompanyID()
  }
  return getRequest(
    `offer/get-list-by-company/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}/${companyID || '%20'}`,
  )
}

export async function find(id) {
  logRequest('Show Offer', JSON.stringify(id))
  return getRequest(`offer/edit/${id}`)
}

export async function find2(id) {
  logRequest('Show Offer', JSON.stringify(id))
  return getRequest(`offer/edit2/${id}`)
}

export async function save(values) {
  logRequest('Save Offer')
  return postRequest(`offer/save`, values)
}

export function remove(values) {
  logRequest('Delete Offer', JSON.stringify(values.values))
  return postRequest(`offer/delete`, values)
}
